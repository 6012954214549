import React, { useEffect, useRef } from "react";
import "./Contact.css";
import anime from "animejs";

function Contact() {
  const contactRef = useRef(null);

  useEffect(() => {
    const animateOnScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollDirection = currentScrollPos >= lastScrollPos ? "down" : "up";
      lastScrollPos = currentScrollPos;

      if (scrollDirection === "down") {
        anime({
          targets: [
            "#sub-title",
            "#main-title",
            "#main-desc",
            "#all-inquires",
            "#email",
            "#socials",
            "#instagram",
            "#linked-in",
          ],
          opacity: [0, 1],
          translateY: [10, 0],
          delay: anime.stagger(100),
          easing: "easeInOutQuad",
          duration: 500,
        });
      }
    };

    let lastScrollPos = window.pageYOffset;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateOnScroll();
          observer.unobserve(entry.target);
        }
      });
    });

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="contact-container" id="contact" ref={contactRef}>
      <div className="work-top">
        <p className="w-sub-title" id="sub-title">
          CONTACT
        </p>
        <p className="w-main-title" id="main-title">
          Let's get in touch.
        </p>
        <p className="contact-desc" id="main-desc">
          Have a project in mind? Drop us a line and let's bring your digital
          vision to <span className="italics">life</span>.{" "}
        </p>
      </div>
      <div className="contact-body">
        <div className="c-left">
          <p className="num c-special" id="all-inquires">
            &#91; ALL INQUIRIES &#93;
          </p>
          <p className="email" id="email">
            hello@adaptimise.ca
          </p>
        </div>
        <div className="space"></div>
        <div className="c-right">
          <p className="num c-special" id="socials">
            &#91; SOCIALS &#93;
          </p>
          <p
            className="social"
            id="instagram"
            onClick={() =>
              window.open(
                "https://www.instagram.com/adaptimise",
                "_blank",
                "noreferrer"
              )
            }
          >
            Instagram
          </p>
          <p
            className="social"
            id="linked-in"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/adaptimise-web-design-studio/",
                "_blank",
                "noreferrer"
              )
            }
          >
            Linked In
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
