import React, { useEffect, useRef } from "react";
import "./Work.css";
import anime from "animejs";
import Projects from "../../components/Projects/Projects";

function Work() {
  const workRef = useRef(null);

  useEffect(() => {
    const animateOnScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollDirection = currentScrollPos >= lastScrollPos ? "down" : "up";
      lastScrollPos = currentScrollPos;

      if (scrollDirection === "down") {
        anime({
          targets: ["#since", "#title"],
          opacity: [0, 1],
          translateY: [10, 0],
          delay: anime.stagger(100),
          easing: "easeInOutQuad",
          duration: 500,
        });
      }
    };

    let lastScrollPos = window.pageYOffset;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateOnScroll();
          observer.unobserve(entry.target);
        }
      });
    });

    if (workRef.current) {
      observer.observe(workRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="work-container" id="work" ref={workRef}>
      <div className="work-top">
        <p className="w-sub-title date" id="since">
          SINCE AUGUST 2022
        </p>
        <h1 className="w-main-title" id="title">
          Our favourite projects.
        </h1>
      </div>
      <div className="gallery-wrapper">
        <Projects />
      </div>
    </div>
  );
}

export default Work;
