import React, { useEffect, useRef } from "react";
import "./Home.css";
import anime from "animejs";

function Home() {
  const homeRef = useRef(null);

  useEffect(() => {
    const animateOnScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollDirection = currentScrollPos >= lastScrollPos ? "down" : "up";
      lastScrollPos = currentScrollPos;

      if (scrollDirection === "down") {
        anime({
          targets: ["#name", "#slogan", "#contact-landing", "#scroll-circle"],
          opacity: [0, 1],
          translateY: [10, 0],
          delay: anime.stagger(100, { start: 1000 }),
          easing: "easeInOutQuad",
          duration: 500,
        });
      }
    };

    let lastScrollPos = window.pageYOffset;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateOnScroll();
          observer.unobserve(entry.target);
        }
      });
    });

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="home-container" id="home" ref={homeRef}>
      <div className="main-text-wrapper">
        <div className="text-wrapper">
          <p className="top-text" id="name">
            &#91; ADAPTIMISE &#93;
          </p>
          <h1 className="main-title" id="slogan">
            We design <span className="thin">bespoke</span>
            <br />
            <span className="thin">digital</span> experiences.
          </h1>
        </div>
        <button
          className="contact-btn"
          id="contact-landing"
          onClick={() =>
            document
              .getElementById("contact")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Get in touch
        </button>
      </div>
      <div
        className="landing-bottom"
        id="scroll-circle"
        onClick={() =>
          document
            .getElementById("about")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <p className="scroll-txt">SCROLL</p>
        <div className="right-bottom">
          <p className="right-bottom-special">&#91; MISSION &#93;</p>
          <p className="right-bottom-txt">
            We meticulously design artisanal websites to craft unforgettable
            digital experiences for your brand.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
