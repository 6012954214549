import React from "react";
import "./Projects.css";
import rowlands from "../../assets/rowlandslanding.jpg";
import hyrewise from "../../assets/hyrewiselanding.jpg";

function Projects() {
  return (
    <div className="proj-wrapper">
      <p className="proj-title">&#91; CASE STUDIES &#93;</p>
      <div className="projects-wrapper">
        <div
          className="project"
          onClick={() =>
            window.open("https://www.rowlandsassociates.com", "_blank")
          }
        >
          <div className="proj-txt-wrapper">
            <p className="proj-num">&#91; 01 &#93;</p>
            <p className="proj-name">Rowlands Associates Inc.</p>
            <p className="proj-date">2023</p>
          </div>
          <img className="project-img" src={rowlands} alt="" height="30%" />
        </div>
        <div
          className="project"
          onClick={() => window.open("https://www.hyrewise.ca", "_blank")}
        >
          <div className="proj-txt-wrapper">
            <p className="proj-num">&#91; 02 &#93;</p>
            <p className="proj-name">Hyrewise</p>
            <p className="proj-date">2023</p>
          </div>
          <img
            className="project-img hyrewise"
            src={hyrewise}
            alt=""
            height="30%"
          />
        </div>
        <div className="project">
          <div className="proj-txt-wrapper">
            <p className="proj-num">&#91; 03 &#93;</p>
            <p className="proj-name">Distortion.pro</p>
            <p className="proj-date">2022</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
