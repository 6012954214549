import "./App.css";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About/About";
import { useInView } from "react-intersection-observer";
import Benefits from "./pages/Benefits/Benefits";
import Contact from "./pages/Contact/Contact";
import Work from "./pages/Work/Work";

function App() {
  const [section1Ref, section1InView] = useInView({ threshold: 0 });
  const [section2Ref, section2InView] = useInView({ threshold: 1 });
  const [section3Ref, section3InView] = useInView({ threshold: 0.9 });

  document.addEventListener("scroll", (event) => {
    const scrollbar = document.getElementById("scrollbar");
    var pos = getVerticalScrollPercentage(document.body);
    scrollbar.style.setProperty("--scrollbar-height", Math.round(pos) + "%");

    function getVerticalScrollPercentage(elm) {
      var p = elm.parentNode;
      return (
        ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) *
        100
      );
    }
  });

  return (
    <div className="App">
      <div>
        <Navbar
          color={
            section2InView || section1InView || section3InView
              ? "#0f0f0f"
              : "#FFFFFF"
          }
        />
      </div>
      <div className="home">
        <Home />
      </div>
      <div ref={section2Ref} className="about">
        <About />
      </div>
      <div ref={section1Ref} className="benefits">
        <Benefits />
      </div>
      <div className="work">
        <Work />
      </div>

      <div ref={section3Ref} className="contact">
        <Contact />
        <div className="footer">
          <div className="location">
            {" "}
            <p className="num f-num">&#91; LOCATIONS &#93;</p>
            <p className="location-txt">OTTAWA</p>
          </div>
          <p className="footer-txt">© 2023 Adaptimise - All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default App;
