import React, { useEffect, useRef } from "react";
import "./About.css";
import anime from "animejs";

function About() {
  const aboutRef = useRef(null);

  useEffect(() => {
    const animateOnScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollDirection = currentScrollPos > lastScrollPos ? "down" : "up";
      lastScrollPos = currentScrollPos;

      if (scrollDirection === "down") {
        anime({
          targets: ["#who-we-are", "#title", "#we-adapt-to-you", "#desc"],
          opacity: [0, 1],
          translateY: [10, 0],
          delay: anime.stagger(100),
          easing: "easeInOutQuad",
          duration: 500,
        });
      }
    };

    let lastScrollPos = window.pageYOffset;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateOnScroll();
          observer.unobserve(entry.target);
        }
      });
    });

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="about-container" id="about">
      <div className="about-text-container">
        <p className="about-top-text" id="who-we-are" ref={aboutRef}>
          WHO ARE WE?
        </p>
        <h1 className="about-main-text" id="title">
          We are a creative design studio that specializes in web design and
          development.
        </h1>
      </div>
      <div className="bottom-text-container">
        <div className="text-wrapper">
          <p className="accent-text" id="we-adapt-to-you">
            &#91; WE ADAPT TO YOU &#93;
          </p>
          <p className="bottom-txt" id="desc">
            We help you find your own unique voice and style. We bring character
            to a templated world by carefully injecting your personality into
            each page. <br /> <br />
            Every page on your website has a story to tell. We focus on making
            visitors feel excited, intrigued, and compelled to keep scrolling.
          </p>
        </div>
        <div className="button-wrapper">
          <button
            className="projects-btn"
            onClick={() =>
              document
                .getElementById("work")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            &#91; VIEW PROJECTS &#93;
          </button>
        </div>
      </div>
    </div>
  );
}

export default About;
