import React, { useEffect, useRef } from "react";
import "./Benefits.css";
import anime from "animejs";

function Benefits() {
  const benefit1Ref = useRef(null);
  const benefit2Ref = useRef(null);
  const benefit3Ref = useRef(null);

  useEffect(() => {
    const animateOnScroll = (animationTargets) => {
      anime({
        targets: animationTargets,
        opacity: [0, 1],
        translateY: [10, 0],
        delay: anime.stagger(100),
        easing: "easeInOutQuad",
        duration: 500,
      });
    };

    let lastScrollPos = window.pageYOffset;

    const observers = [
      {
        ref: benefit1Ref,
        targets: ["#benifetNum1", "#benifetNum1Title", "#benifetNum1Desc"],
      },
      {
        ref: benefit2Ref,
        targets: ["#benifetNum2", "#benifetNum2Title", "#benifetNum2Desc"],
      },
      {
        ref: benefit3Ref,
        targets: ["#benifetNum3", "#benifetNum3Title", "#benifetNum3Desc"],
      },
    ];

    observers.forEach((observerConfig) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const currentScrollPos = window.pageYOffset;
          const scrollDirection =
            currentScrollPos > lastScrollPos ? "down" : "up";
          lastScrollPos = currentScrollPos;

          if (entry.isIntersecting && scrollDirection === "down") {
            animateOnScroll(observerConfig.targets);
            observer.unobserve(entry.target);
          }
        });
      });

      if (observerConfig.ref.current) {
        observer.observe(observerConfig.ref.current);
      }

      return () => {
        observer.disconnect();
      };
    });
  }, []);

  return (
    <div className="benefits-container">
      <div className="benefit-wrapper b1">
        <p className="num b-num" id="benifetNum1" ref={benefit1Ref}>
          &#91; 01 &#93;
        </p>
        <h1 className="benefit-title" id="benifetNum1Title">
          No drag and drop builders.
        </h1>
        <p className="benefit-desc" id="benifetNum1Desc">
          Drag and drop builders are not ideal for custom design, as the end
          result will always look like a template. We take the time to create
          designs that are unique to your brand to help you stand out amongst
          your competitors.
        </p>
      </div>
      <div className="benefit-wrapper b2">
        <p className="num b-num" id="benifetNum2" ref={benefit2Ref}>
          &#91; 02 &#93;
        </p>
        <h1 className="benefit-title" id="benifetNum2Title">
          Everything built in-house
        </h1>
        <p className="benefit-desc" id="benifetNum2Desc">
          No more communication problems. Each site is designed and developed
          in-house with absolutely zero outsourcing. You know exactly who and
          what you’re paying for.
        </p>
      </div>
      <div className="benefit-wrapper b3">
        <p className="num b-num" id="benifetNum3" ref={benefit3Ref}>
          &#91; 03 &#93;
        </p>
        <h1 className="benefit-title" id="benifetNum3Title">
          Created from the ground up.
        </h1>
        <p className="benefit-desc" id="benifetNum3Desc">
          We take the time to produce each website from scratch, ensuring that
          it is well-optimized for speed and user experience.
        </p>
      </div>
    </div>
  );
}

export default Benefits;
