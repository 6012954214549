import React from "react";
import { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import anime from "animejs";

function Navbar({ color }) {
  const [oppositeColor, setOppositeColor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navItemsRef = useRef(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getOppositeColor = (color) => {
    if (color === "#FFFFFF") {
      setOppositeColor("rgba(15, 15, 15, 0.4)");
    } else {
      setOppositeColor("rgba(255, 255, 255, 0.4)");
    }
  };

  anime({
    targets: ["#logo", "#navBar"],
    opacity: [0, 1],
    translateY: [-10, 0],
    delay: anime.stagger(100),
    easing: "easeInOutQuad",
    duration: 500,
  });

  const animateNavOpen = () => {
    const timeline = anime.timeline();
    timeline.add({
      targets: ".nav-items a",
      translateY: [-20, 0],
      opacity: [0, 1],
      duration: 300,
      easing: "easeOutExpo",
      delay: anime.stagger(100),
    });
  };

  const animateNavClose = () => {
    anime({
      targets: ".nav-items a",
      translateY: -20,
      opacity: 0,
      duration: 200,
      easing: "easeOutExpo",
      complete: () => {
        // Reset any necessary styles or states after the animation
      },
    });
  };

  const toggleNav = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      animateNavOpen();
    } else {
      animateNavClose();
    }
  };

  const handleLinkHover = (link) => {
    setHoveredLink(link);
  };

  const isLinkHovered = (link) => {
    return link === hoveredLink;
  };

  useEffect(() => {
    getOppositeColor(color);
    const navLinks = navItemsRef.current.querySelectorAll("a");
    const animations = [];

    navLinks.forEach((navLink) => {
      const animation = anime({
        targets: navLink,
        translateY: [10, 0],
        duration: 300,
        easing: "easeOutQuad",
        autoplay: false,
      });

      animations.push(animation);

      navLink.addEventListener("mouseenter", () => {
        animation.play();
      });

      navLink.addEventListener("mouseleave", () => {});
    });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      navLinks.forEach((navLink, index) => {
        navLink.removeEventListener("mouseenter", animations[index].play);
        navLink.removeEventListener("mouseleave", animations[index].reverse);
      });
    };
  }, [color]);

  /*
  className={`link-svg ${
    hoveredLink === "home"
      ? "home-hovered"
      : hoveredLink === "about"
      ? "about-hovered"
      : hoveredLink === "work"
      ? "work-hovered"
      : hoveredLink === "contact"
      ? "contact-hovered"
      : ""
  }`}
*/
  const shouldApplyBackgroundColor = windowWidth <= 640;

  return (
    <div
      className="nav-wrapper"
      style={
        shouldApplyBackgroundColor ? { backgroundColor: oppositeColor } : {}
      }
    >
      <div className="left-side">
        <div className="left-wrapper" style={{ color: color }}>
          <svg
            className="logosvg"
            width="40"
            height="36.8"
            viewBox="0 0 50 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="logo"
          >
            <g clipPath="url(#clip0_1474_137)">
              <path
                d="M50 45.4654L25 0.535156V12.3026L36.413 37.977L50 45.4654Z"
                fill="currentColor"
              />
              <path d="M25 0L0 41.7209L25 11.7674V0Z" fill="currentColor" />
              <path
                d="M36.413 37.9766L50 45.4649H0L36.413 37.9766Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_1474_137">
                <rect width="50" height="46" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="right-side">
        <div className="right-wrapper" id="scrollbar">
          <div className="hamburger-container">
            <div className="hamburger" onClick={toggleNav} id="navBar">
              <div className="line" style={{ backgroundColor: color }}></div>
              <div className="line" style={{ backgroundColor: color }}></div>
            </div>
          </div>
        </div>
      </div>
      <nav className={isOpen ? "open" : ""}>
        <div className="container-nav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 800 800"
            opacity="1"
            className={`link-svg ${
              hoveredLink === "home"
                ? "home-hovered"
                : hoveredLink === "about"
                ? "about-hovered"
                : hoveredLink === "work"
                ? "work-hovered"
                : hoveredLink === "contact"
                ? "contact-hovered"
                : ""
            }`}
          >
            <g strokeWidth="3" fill="none" strokeLinecap="round">
              <path
                d="M30.000001764297465 420.75771197853174C40.27777941138655 400.3820177931776 71.11111235265378 288.9837553207909 91.66666764683193 298.5035468664072C112.22222294101007 308.02333841202346 132.7777782351882 476.3733362713425 153.33333352936637 477.8764612522293C173.88888882354453 479.3795862331161 194.4444441177227 313.86882444880536 214.99999941190086 307.5222967517278C235.55555470607902 301.17576905465035 256.1111100002571 436.2900034476951 276.6666652944353 439.79729506976423C297.2222205886134 443.3045866918334 317.7777758827916 331.57229644591627 338.33333117696975 328.5660464841427C358.88888647114794 325.5597965223692 379.4444417653261 422.0938230726533 399.9999970595042 421.75979529912297C420.55555235368234 421.4257675255925 441.11110764786054 314.20285222233576 461.66666294203867 326.56187984296037C482.2222182362168 338.92090746358497 502.77777353039494 493.90979438168824 523.3333288245731 495.91396102287064C543.8888841187513 497.91812766405303 564.4444394129293 337.5847963694634 584.9999947071076 338.58687969005456C605.5555500012857 339.5889630106458 626.1111052954639 496.91604434346186 646.6666605896421 501.9264609464177C667.2222158838202 506.93687754937366 687.7777711779984 381.1754208151799 708.3333264721765 368.6493793077901C728.8888817663548 356.1233378004003 759.7222147076219 417.0834064696974 769.999992354711 426.77021190207887 "
                transform="rotate(110, 400, 400)"
              ></path>
              <path
                d="M39.25000172019003 420.23876727171984C49.270834926101884 400.3724654409996 79.33333454383742 291.7591595304225 99.37500095566114 301.0409562873984C119.41666736748482 310.3227530443742 139.4583337793085 474.4640009572104 159.50000019113224 475.9295478135749C179.54166660295596 477.39509466993957 199.5833330147796 316.02210193023654 219.62499942660332 309.834237425586C239.66666583842704 303.64637292093545 259.7083322502507 435.3827514541541 279.7499986620744 438.8023607856715C299.79166507389806 442.22197011718896 319.8333314857218 333.28298712741974 339.87499789754554 330.35189341469055C359.9166643093692 327.4207997019613 379.95833072119297 421.54147558848837 399.9999971330166 421.21579850929623C420.04166354484033 420.8901214301041 440.08332995666404 316.34777900942873 460.1249963684877 328.3978309395377C480.1666627803114 340.4478828696467 500.2083291921351 491.5620476147974 520.2499956039588 493.5161100899502C540.2916620157825 495.47017256510304 560.3333284276061 339.1451745528782 580.37499483943 340.1222057904546C600.4166612512536 341.099237028031 620.4583276630773 494.49314132752664 640.499994074901 499.3782975154087C660.5416604867247 504.2634537032907 680.5833268985483 381.64603338745184 700.6249933103721 369.43314291774675C720.6666597221958 357.22025244804166 750.7291593399314 416.6563194006064 760.7499925458433 426.1009546971783 "
                transform="rotate(107.25, 400, 400)"
              ></path>
              <path
                d="M48.50000167608259 419.71985308248605C58.26389044081722 400.36294360639965 87.55555673502109 294.53459425763225 107.08333426449033 303.5783962259677C126.61111179395957 312.6221981943031 146.1388893234288 472.55469616065625 165.66666685289806 473.9826648924987C185.19444438236732 475.41063362434113 204.72222191183656 318.1754099292459 224.2499994413058 312.1462086170223C243.7777769707751 306.1170073047987 263.3055545002443 434.4755299781912 282.8333320297136 437.8074570191569C302.36110955918275 441.1393840601226 321.88888708865204 334.99370832650135 341.4166646181213 332.13777086281647C360.9444421475905 329.2818333991316 380.4722196770598 420.98915862190154 399.999997206529 420.6718322370477C419.52777473599826 420.35450585219377 439.0555522654675 318.49273631409983 458.58332979493673 330.2338125536932C478.11110732440596 341.9748887932866 497.63888485387514 489.21433136548467 517.1666623833444 491.1182896746079C536.6944399128138 493.0222479837312 556.222217442283 340.70558325387105 575.7499949717522 341.6575624084327C595.2777725012215 342.60954156299437 614.8055500306906 492.07026882916955 634.33332756016 496.83016460197774C653.8611050896293 501.5900603747858 673.3888826190985 382.1166764773018 692.9166601485678 370.2169370452815C712.4444376780369 358.31719761326116 741.7361039722408 416.2292628490934 751.4999927369754 425.43172800985576 "
                transform="rotate(104.5, 400, 400)"
              ></path>
              <path
                d="M57.75000163197515 419.200893116885C67.25694595553256 400.3533759954325 95.77777892620475 297.30998320847476 114.79166757331953 306.1157903881698C133.8055562204343 314.92159756786486 152.81944486754912 470.645345587735 171.8333335146639 472.0357361950553C190.84722216177872 473.4261268023755 209.86111080889347 320.3286721518881 228.87499945600828 314.45813403209144C247.8888881031231 308.5875959122947 266.9027767502379 433.56826272586113 285.91666539735263 436.8125074762751C304.93055404446744 440.05675222668907 323.94444269158225 336.7043837492157 342.958331338697 333.9236025345752C361.9722199858118 331.1428213199347 380.98610863292663 420.4367958789475 399.99999728004144 420.1278201884319C419.01388592715625 419.81884449791625 438.027774574271 320.63764784240374 457.04166322138576 332.0697483914815C476.0555518685006 343.5018489405593 495.0694405156153 486.86656933980476 514.0833291627303 488.7204234828985C533.097217809845 490.5742776259922 552.1111064569598 342.2659461784968 571.1249951040745 343.19287325004365C590.1388837511894 344.1198003215905 609.1527723983041 489.6473505544453 628.1666610454189 494.28198591217955C647.1805496925338 498.9166212699138 666.1944383396484 382.5872737907846 685.2083269867633 371.00068539644906C704.2222156338781 359.41409700211346 732.7430486045503 415.8021605212133 742.2499929281076 424.7624555461661 "
                transform="rotate(101.75, 400, 400)"
              ></path>
              <path
                d="M67.00000158786771 418.68193315128406C76.25000147024788 400.3438083844653 104.0000011173884 300.08537215931733 122.50000088214874 308.6531845503719C141.0000006469091 317.22099694142656 159.50000041166942 468.73599501481374 178.00000017642975 470.0888074976118C196.49999994119008 471.44161998040994 214.99999970595042 322.4819343745303 233.49999947071078 316.7700594471605C251.9999992354711 311.05818451979076 270.49999900023147 432.66099547353105 288.99999876499174 435.8175579333933C307.49999852975213 438.97412039325553 325.99999829451247 338.4150591719301 344.4999980592728 335.7094342063339C362.99999782403313 333.0038092407377 381.4999975887935 419.88443313599345 399.9999973535538 419.5838081398161C418.4999971183141 419.2831831436387 436.9999968830745 322.78255937070765 455.4999966478348 333.9056842292698C473.9999964125952 345.028809087832 492.4999961773554 484.5188073141249 510.9999959421158 486.322557291189C529.4999957068762 488.12630726825313 547.9999954716363 343.8263091031225 566.4999952363968 344.7281840916546C584.9999950011572 345.63005908018664 603.4999947659174 487.2244322797211 621.9999945306778 491.7338072223814C640.4999942954382 496.24318216504173 658.9999940601986 383.0578711042674 677.4999938249589 371.78443374761656C695.9999935897192 360.5109963909657 723.7499932368597 415.37505819333313 732.9999931192399 424.09318308247646 "
                transform="rotate(99, 400, 400)"
              ></path>
              <path
                d="M76.25000154376028 418.1629960738666C85.24305698496322 400.3342636616818 112.22222330857205 302.86078399834344 130.20833419097795 311.19060160075765C148.1944450733838 319.52041920317185 166.1805559557897 466.826667330076 184.16666683819557 468.141901688352C202.15277772060148 469.4571360466279 220.13888860300733 324.63521948535606 238.12499948541324 319.08200775041325C256.11111036781915 313.52879601547045 274.097221250225 431.7537511093846 292.08333213263086 434.8226312786951C310.06944301503677 437.8915114480056 328.0555538974427 340.12575748282813 346.04166477984853 337.49528876627625C364.0277756622544 334.86482004972436 382.01388654466035 419.332093281223 399.9999974270662 419.03981897938394C417.98610830947206 418.74754467754485 435.97221919187797 324.92749378719515 453.9583300742838 335.7416429552417C471.94444095668973 346.5557921232882 489.93055183909553 482.1710681766286 507.91666272150144 483.92471398766315C525.9027736039073 485.6783597986977 543.8888844863131 345.38669491593186 561.874995368719 346.2635178214491C579.8611062511251 347.14034072696643 597.8472171335309 484.80153689318047 615.8333280159368 489.1856514207668C633.8194388983427 493.5697659483533 651.8055497807485 383.5284913059338 669.7916606631544 372.5682049869677C687.7777715455603 361.6079186680016 714.7569378691692 414.94797875363656 723.7499933103721 423.42393350697034 "
                transform="rotate(96.25, 400, 400)"
              ></path>
              <path
                d="M85.50000149965285 417.64405899644925C94.23611249967855 400.3247189388983 120.44444549975572 305.63619583736954 137.91666749980715 313.7280186511434C155.38888949985858 321.8198414649172 172.86111149990998 464.9173396453384 190.33333349996144 466.1949958790922C207.80555550001287 467.47265211284594 225.27777750006427 326.78850459618184 242.74999950011573 321.39395605366593C260.22222150016717 315.99940751115 277.69444350021854 430.8465067452381 295.16666550027 433.82770462399685C312.6388875003214 436.80890250275564 330.1111095003729 341.8364557937261 347.5833315004243 339.28114332621857C365.0555535004757 336.72583085871105 382.5277755005272 418.7797534264526 399.9999975005786 418.49582981895173C417.47221950063 418.2119062114509 434.9444415006815 327.07242820368265 452.4166635007329 337.57760168121354C469.88888550078434 348.0827751587445 487.3611075008357 479.82332903913226 504.83332950088715 481.5268706841373C522.3055515009387 483.23041232914227 539.77777350099 346.9470807287411 557.2499955010414 347.7988515512437C574.7222175010929 348.65062237374616 592.1944395011443 482.3786415066398 609.6666615011958 486.63749561915233C627.1388835012472 490.8963497316648 644.6111055012985 383.9991115076002 662.08332750135 373.35197622631887C679.5555495014015 362.7048409450375 705.7638825014786 414.52089931394005 714.4999935015044 422.7546839314643 "
                transform="rotate(93.5, 400, 400)"
              ></path>
              <path
                d="M94.7500014555454 417.12511428963734C103.22916801439389 400.3151665867202 128.66666769093936 308.4116000470011 145.62500080863634 316.26542807213457C162.5833339263333 324.11925609726796 179.54166704403028 463.00800433120617 196.50000016172726 464.24808244043777C213.45833327942427 465.4881605496694 230.41666639712122 328.941782077613 247.3749995148182 323.7058967275241C264.3333326325152 318.4700113774352 281.29166575021213 429.9392547516971 298.2499988679091 432.83277033990413C315.2083319856061 435.7262859281112 332.1666651033031 343.54714647522957 349.12499822100006 341.0669902567664C366.083331338697 338.5868340383032 383.041664456394 418.22740594228765 399.99999757409097 417.95183302912505C416.958330691788 417.67626011596246 433.916663809485 329.2173549907756 450.87499692718194 339.4135527777909C467.8333300448789 349.60975056480623 484.7916631625758 477.47558227224147 501.7499962802728 479.1290197512169C518.7083293979699 480.78245723019234 535.6666625156668 348.5074589121559 552.6249956333638 349.33417765164364C569.5833287510608 350.16089639113136 586.5416618687577 479.95573849070456 603.4999949864547 484.08933218814326C620.4583281041516 488.2229258855819 637.4166612218486 384.4697240798721 654.3749943395457 374.1357398362755C671.3333274572426 363.8017555926789 696.7708271337881 414.093812244849 705.2499936926366 422.0854267265637 "
                transform="rotate(90.75, 400, 400)"
              ></path>
              <path
                d="M104.00000141143798 416.6061772122199C112.22222352910923 400.30562186393666 136.888889882123 311.1870118860273 153.33333411746554 318.8028451225203C169.77777835280807 326.41867835901326 186.22222258815057 461.09867664646856 202.6666668234931 462.301176631178C219.11111105883563 463.50367661588734 235.55555529417813 331.0950671884388 251.99999952952066 326.0178450307768C268.4444437648632 320.9406228731148 284.8888880002057 429.0320103875506 301.33333223554826 431.83784368520594C317.77777647089073 434.6436769828613 334.2222207062333 345.2578447861276 350.6666649415758 342.85284481670874C367.1111091769183 340.44784484728984 383.55555341226085 417.6750660875172 399.9999976476034 417.4078438686929C416.4444418829459 417.14062164986854 432.88888611828844 331.3622894072631 449.33333035363097 341.24951150376285C465.77777458897344 351.1367336002625 482.222218824316 475.12784313474515 498.6666630596585 476.73117644769104C515.111107295001 478.33450976063693 531.5555515303436 350.06784472496525 547.9999957656861 350.8695113814382C564.4444400010286 351.67117803791115 580.888884236371 477.532843104164 597.3333284717137 481.5411763865287C613.7777727070562 485.54950966889345 630.2222169423986 384.9403442815385 646.6666611777412 374.91951107562664C663.1111054130838 364.8986778697148 687.7777717660975 413.66673280515244 695.9999938837688 421.4161771510576 "
                transform="rotate(88, 400, 400)"
              ></path>
              <path
                d="M113.25000136733053 416.0872401348025C121.21527904382457 400.2960771411531 145.11111207330669 313.9624237250534 161.04166742629474 321.340262172906C176.97222277928282 328.7181006207586 192.90277813227087 459.18934896173084 208.83333348525895 460.35427082191813C224.763888838247 461.5191926821054 240.69444419123508 333.2483522992646 256.62499954422316 328.32979333402955C272.5555548972112 323.41123436879445 288.4861102501993 428.12476602340416 304.41666560318737 430.8429170305077C320.3472209561754 433.56106803761134 336.2777763091635 346.96854309702553 352.2083316621516 344.63869937665106C368.13888701513963 342.30885565627653 384.0694423681277 417.12272623274674 399.9999977211158 416.8638547082607C415.93055307410384 416.6049831837746 431.86110842709195 333.5072238237506 447.79166378008 343.0854702297347C463.72221913306805 352.6637166357188 479.65277448605605 472.78010399724883 495.58332983904415 474.3333331441652C511.51388519203226 475.88656229108153 527.4444405450203 351.6282305377746 543.3749958980084 352.4048451112327C559.3055512509965 353.1814596846909 575.2361066039845 475.1099477176233 591.1666619569726 478.9930205849142C607.0972173099607 482.876093452205 623.0277726629487 385.4109644832049 638.9583280159368 375.7032823149778C654.8888833689249 365.9956001467507 678.784716398407 413.2396533654559 686.749994074901 420.7469275755515 "
                transform="rotate(85.25, 400, 400)"
              ></path>
              <path
                d="M122.5000013232231 415.5682954279906C130.2083345585399 400.28652478897504 153.33333426449033 316.737827934685 168.75000073512393 323.87767159389716C184.16666720575756 331.01751525310937 199.58333367639116 457.2800136475987 215.00000014702476 458.40735738326373C230.41666661765842 459.53470111892887 245.83333308829202 335.4016297806958 261.24999955892565 330.6417340078877C276.6666660295593 325.88183823507956 292.0833325001929 427.21751402986314 307.4999989708265 429.84798274641497C322.91666544146005 432.47845146296686 338.33333191209374 348.679233778529 353.7499983827273 346.42454630719885C369.1666648533609 344.1698588358687 384.58333132399457 416.5703787485818 399.9999977946282 416.31985791843397C415.4166642652618 416.0693370882862 430.83333073589546 335.6521506108436 446.24999720652903 344.92142132631204C461.66666367716266 354.19069204178055 477.08333014779623 470.432357230358 492.49999661842986 471.9354822112448C507.91666308906343 473.43860719213154 523.3333295596971 353.1886087211893 538.7499960303306 353.94017121163273C554.1666625009643 354.69173370207614 569.5833289715979 472.68704470168814 584.9999954422315 476.4448571539051C600.4166619128652 480.2026696061221 615.8333283834988 385.8815770554768 631.2499948541324 376.48704592493436C646.666661324766 367.09251479439206 669.7916610307165 412.8125662963648 677.4999942660332 420.07767037065094 "
                transform="rotate(82.5, 400, 400)"
              ></path>
              <path
                d="M131.75000127911568 415.04933546238965C139.20139007325525 400.27695717800793 161.55555645567398 319.5132168855275 176.45833404395316 326.4150657560993C191.3611116322323 333.31691462667106 206.26388922051146 455.3706630746774 221.16666680879064 456.4604286858204C236.0694443970698 457.55019429696324 250.97222198534894 337.55489200333795 265.8749995736281 332.9536594229568C280.7777771619073 328.3524268425756 295.68055475018645 426.31024677753305 310.58333233846554 428.8530332035332C325.4861099267447 431.3958196295333 340.38888751502395 350.3899092012434 355.2916651033031 348.2103779789576C370.19444269158225 346.03084675667174 385.0972202798614 416.01801600562777 399.99999786814055 415.7758458698182C414.9027754564197 415.5336757340087 429.8055530446989 337.79706213914756 444.70833063297806 346.75735716410037C459.6111082212572 355.7176521890532 474.5138858095363 468.08459520467807 489.4166633978155 469.53761601953533C504.3194409860947 470.9906368343925 519.2222185743739 354.74897164581506 534.1249961626529 355.4754820532437C549.0277737509323 356.2019924606723 563.9305513392112 470.2641264269639 578.8333289274905 473.8966784641069C593.7361065157697 477.52923050125 608.6388841040488 386.35217436895954 623.541661692328 377.2707942761019C638.4444392806072 368.1894141832443 660.7986056630259 412.3854639684847 668.2499944571655 419.4083979069613 "
                transform="rotate(79.75, 400, 400)"
              ></path>
              <path
                d="M141.00000123500823 414.5304212731558C148.19444558797056 400.26743534340795 169.77777864685766 322.2886516127372 184.16666735278235 328.9525056946686C198.55555605870705 335.6163597766 212.94444476463175 453.4613582781234 227.33333347055645 454.5135457647441C241.72222217648118 455.5657332513648 256.1111108824059 339.7082000023473 270.49999958833064 335.2656306143931C284.8888882942553 330.8230612264388 299.27777700018 425.4030253015702 313.6666657061047 427.8581294370186C328.05555441202944 430.31323357246697 342.44444311795417 352.10063040032503 356.83333182387884 349.9962554270835C371.2222205298035 347.891880453842 385.61110923572824 415.46569903904094 399.99999794165296 415.2318795975697C414.3888866475777 414.99806015609835 428.77777535350236 339.9420194438186 443.1666640594271 348.5933387782558C457.55555276535176 357.24465811269306 471.94444147127643 465.73687895536534 486.3333301772012 467.13979560419307C500.72221888312595 468.5427122530207 515.1111075890506 356.309380346808 529.4999962949753 357.0108386712218C543.8888850009 357.7122969956356 558.2777737068247 467.8412539286069 572.6666624127495 471.348545550676C587.0555511186742 474.85583717274517 601.4444398245988 386.82281745880954 615.8333285305237 378.05458840363667C630.2222172364483 369.2863593484638 651.8055502953354 411.9584074169718 658.9999946482977 418.7391712196388 "
                transform="rotate(77, 400, 400)"
              ></path>
              <path
                d="M150.25000119090078 414.01146130755484C157.18750110268593 400.25786773244084 178.0000008380413 325.06404056357974 191.87500066161155 331.48989985687075C205.7500004851818 337.9157591501617 219.62500030875202 451.5520077052021 233.50000013232233 452.5666170673007C247.37499995589258 453.58122642939924 261.24999977946277 341.8614622249895 275.1249996030331 337.5775560294622C288.9999994266034 333.29364983393486 302.8749992501736 424.4957580492401 316.7499990737438 426.8631798941368C330.6249988973141 429.23060173903343 344.4999987208844 353.8113058230394 358.37499854445457 351.7820870988422C372.2499983680248 349.7528683746451 386.1249981915951 414.9133362960869 399.9999980151654 414.6878675489539C413.87499783873557 414.4623988018209 427.7499976623059 342.08693097212256 441.6249974858761 350.42927461604415C455.49999730944637 358.77161825996575 469.37499713301656 463.38911692968543 483.2499969565868 464.74192941248356C497.1249967801571 466.0947418952817 510.9999966037273 357.86974327143366 524.8749964272977 358.54614951283276C538.749996250868 359.2225557542318 552.6249960744381 465.41833565388265 566.4999958980084 468.8003668608779C580.3749957215787 472.1823980678731 594.2499955451489 387.29341477229235 608.124995368719 378.83833675480423C621.9999951922894 370.3832587373161 642.8124949276448 411.53130508909163 649.74999483943 418.0698987559491 "
                transform="rotate(74.25, 400, 400)"
              ></path>
              <path
                d="M159.50000114679335 413.4925242301374C166.18055661740124 400.24832300965727 186.22222302922495 327.8394524026059 199.58333397044075 334.0273169072565C212.94444491165655 340.21518141190705 226.30555585287235 449.64268002046447 239.66666679408814 450.61971125804087C253.02777773530397 451.59674249561726 266.38888867651974 344.0147473358153 279.74999961773557 339.88950433271486C293.1111105589514 335.76426132961456 306.47222150016717 423.58851368509363 319.83333244138294 425.86825323943856C333.1944433825987 428.1479927937835 346.5555543238146 355.5220041339374 359.91666526503036 353.56794165878455C373.27777620624613 351.61387918363175 386.63888714746196 414.36099644131644 399.9999980886778 414.1438783885217C413.36110902989356 413.92676033572695 426.7222199711094 344.23186538861 440.08333091232515 352.265233342016C453.4444418535409 360.29860129542203 466.8055527947567 461.04137779218917 480.1666637359725 462.3440861089577C493.52777467718835 463.6467944257263 506.8888856184041 359.430129084243 520.24999655962 360.08148324262726C533.6111075008357 360.7328374010116 546.9722184420516 462.99544026734196 560.3333293832674 466.25221105926335C573.6944403244831 469.50898185118467 587.0555512656989 387.76403497395876 600.4166622069147 379.6221079941554C613.7777731481306 371.480181014352 633.8194395599542 411.1042256493951 640.4999950305621 417.40064918044305 "
                transform="rotate(71.5, 400, 400)"
              ></path>
              <path
                d="M168.75000110268593 412.97357570862823C175.1736121321166 400.23876684278196 194.44444522040862 330.6148527975402 207.29166727926997 336.5647225135504C220.13888933813132 342.51459222956055 232.98611139699264 447.73334089163495 245.833333455854 448.6727940046892C258.68055551471537 449.61224711774344 271.5277775735767 346.16802100254927 284.374999632438 342.2014411918758C297.2222216912994 338.23486138120234 310.06944375016076 422.68125787685534 322.91666580902205 424.8733151406486C335.7638878678834 427.06537240444175 348.61110992674475 357.23269100074356 361.45833198560615 355.3537847746351C374.30555404446744 353.47487854852665 387.15277610332885 413.8086451424542 399.99999816219014 413.5998777839977C412.8472202210515 413.3911104255412 425.69444227991283 346.37678836100577 438.5416643387742 354.10118062389614C451.38888639763553 361.8255728867865 464.2361084564968 458.69362721060105 477.08333051535817 459.94623136134004C489.9305525742196 461.19883551207903 502.77777463308087 360.9905034529605 515.6249966919422 361.61680552833C528.4722187508037 362.2431076036995 541.3194408096649 460.57253343670953 554.1666628685263 463.70404381355695C567.0138849273876 466.8355541904044 579.8611069862488 388.23464373153337 592.7083290451103 380.40586778941474C605.5555511039718 372.5770918472961 624.8263841922637 410.67713476560675 631.2499952216943 416.7313881608452 "
                transform="rotate(68.75, 400, 400)"
              ></path>
              <path
                d="M178.00000105857848 412.4546348165136C184.1666676468319 400.2292183053011 202.66666741159227 333.3902608218691 215.00000058809917 339.10213574923887C227.33333376460604 344.8140106766086 239.66666694111294 445.8240093922 252.00000011761983 446.7258843807321C264.33333329412676 447.62775936926414 276.6666664706336 348.3213022986777 288.9999996471405 344.51338568043127C301.3333328236474 340.70546906218476 313.6666660001543 421.7740096980116 325.99999917666116 423.8783846712531C338.3333323531681 425.9827596444946 350.666665529675 358.9433854969443 362.9999987061818 357.1396355198802C375.33333188268875 355.3358855428161 387.6666650591957 413.25630147298654 399.99999823570255 413.0558848088683C412.3333314122094 412.85546814475003 424.66666458871634 348.521718962796 436.9999977652232 355.93713553517074C449.3333309417301 363.35255210754553 461.66666411823695 456.34588425840747 473.9999972947439 457.5483842431169C486.3333304712508 458.7508842278263 498.6666636477577 362.55088545107253 510.99999682426454 363.15213544342726C523.3333300007714 363.753385435782 535.6666631772782 458.1496342354716 547.9999963537853 461.1558841972452C560.3333295302922 464.1621341590187 572.666662706799 388.7052601185025 584.9999958833059 381.18963521406863C597.3333290598127 373.6740103096347 615.8333288245731 410.250051511213 621.9999954128266 416.0621347706418 "
                transform="rotate(66, 400, 400)"
              ></path>
              <path
                d="M187.25000101447102 411.93568248030715C193.15972316154728 400.21965832372854 210.88888960277592 336.16565740210615 222.70833389692837 341.6395375408355C234.5277781910808 347.11341767956486 246.3472224852332 443.9146664486733 258.16666677938565 444.77896331268323C269.9861110735381 445.6432601766931 281.80555536769054 350.47457215071444 293.624999661843 346.8253187248949C305.44444395599544 343.17606529907533 317.26388825014783 420.8667500750761 329.08333254430033 422.8834427577658C340.9027768384527 424.9001354404556 352.7222211326052 360.65406854905325 364.5416654267576 358.92547482103345C376.36110972091006 357.19688109301364 388.1805540150625 412.70394635942705 399.9999983092149 412.51188038964705C411.81944260336735 412.31981441986704 423.6388868975198 350.66663812049444 435.4583311916723 357.7730790023536C447.2777754858247 364.87951988421275 459.0972197799771 453.99812986212214 470.9166640741295 455.150525680802C482.73610836828203 456.30292149948184 494.5555526624344 364.11125600509286 506.37499695658687 364.68745391443275C518.1944412507394 365.26365182377265 530.0138855448918 455.7267235901419 541.8333298390442 458.60771313684154C553.6527741331967 461.4887026835412 565.4722184273489 389.17586506137985 577.2916627215016 381.9733911946307C589.1111070156539 374.77091732788153 606.8402734568826 409.8229568127274 612.7499956039588 415.3928699363467 "
                transform="rotate(63.25, 400, 400)"
              ></path>
              <path
                d="M196.5000009703636 411.4167377734952C202.15277867626259 400.21010597155043 219.1111117939596 338.9410616117377 230.41666720575756 344.1769469618267C241.72222261755553 349.4128323119156 253.02777802935353 442.0053311345411 264.3333334411515 442.8320498740288C275.6388888529495 443.6587686135166 286.9444442647475 352.6278496321457 298.2499996765455 349.13725939875303C309.55555508834345 345.64666916536044 320.8611105001414 419.95949808153506 332.1666659119394 421.8885084736731C343.47222132373736 423.8175188658111 354.77777673553544 362.3647592305567 366.0833321473334 360.71132175158124C377.3888875591314 359.05788427260575 388.69444297092934 412.15159887526204 399.9999983827273 411.9678835998203C411.3055537945253 411.78416832437864 422.61110920632336 352.8115649075874 433.91666461812133 359.60903009893093C445.2222200299193 366.4064952902745 456.5277754417172 451.6503830952313 467.8333308535152 452.75267474788154C479.1388862653132 453.85496640053185 490.4444416771112 365.6716341885076 501.74999708890914 366.2227800148328C513.0555525007071 366.7739258411579 524.3611079125051 453.3038205742067 535.6666633243032 456.05954970583247C546.9722187361012 458.81527883745827 558.2777741478991 389.64647763365167 569.5833295596971 382.7571548045873C580.8888849714951 375.8678319755229 597.847218089192 409.3958697436363 603.4999957950911 414.7236127314461 "
                transform="rotate(60.5, 400, 400)"
              ></path>
              <path
                d="M205.75000092625618 410.8978045107751C211.14583419097795 400.2005650634642 227.3333339851432 341.71647726546115 238.12500051458676 346.71436782690967C248.9166670440303 351.71225838835824 259.70833357347385 440.0960072645007 270.50000010291734 440.8851478794663C281.2916666323609 441.67428849443183 292.08333316180443 354.78113855766867 302.8749996912479 351.44921151670303C313.66666622069147 348.11728447573734 324.458332750135 419.05225753208583 335.24999927957856 420.89358563367216C346.04166580902205 422.73491373525843 356.8333323384656 364.07546135615195 367.6249988679091 362.4971801262208C378.41666539735263 360.9188988962897 389.2083319267962 411.5992628351889 399.9999984562397 411.42389825408543C410.79166498568327 411.248533672982 421.5833315151268 354.9565031387722 432.37499804457036 361.44499263960006C443.1666645740138 367.933482140428 453.95833110345734 449.30264777243224 464.7499976329009 450.35483525905295C475.54166416234443 451.4070227456737 486.3333306917879 367.2320238160142 497.12499722123147 367.75811755932455C507.916663750675 368.28421130263496 518.7083302801185 450.88092900236336 529.499996809562 453.5113977189152C540.2916633390056 456.1418664354671 551.0833298684491 390.11710165001534 561.8749963978927 383.5409298586357C572.6666629273362 376.96475806725607 588.8541627215016 408.96879411863705 594.2499959862232 414.0543669706373 "
                transform="rotate(57.75, 400, 400)"
              ></path>
              <path
                d="M215.00000088214873 410.37885980396317C220.13888970569326 400.1910127112861 235.5555561763269 344.4918814750927 245.83333382341596 349.25177724790086C256.111111470505 354.011673020709 266.3888891175941 438.1866719503685 276.6666667646832 438.9382344408119C286.9444444117723 439.6897969312553 297.22222205886135 356.9344160390999 307.4999997059504 353.7611521905612C317.7777773530395 350.58788834202244 328.05555500012855 418.1450055385448 338.3333326472176 419.8986513495794C348.61111029430674 421.65229716061395 358.8888879413958 365.7861520376554 369.1666655884849 364.2830270567686C379.444443235574 362.77990207588186 389.722220882663 411.0469153510239 399.99999852975213 410.87990146425875C410.27777617684114 410.7128875774935 420.55555382393027 357.10142992586515 430.83333147101933 363.28094373617745C441.1111091181084 369.46045754648975 451.38888676519747 446.9549010055414 461.66666441228654 447.95698432613256C471.94444205937566 448.9590676467238 482.22221970646467 368.792401999429 492.4999973535538 369.2934436597246C502.77777500064286 369.79448532002016 513.0555526477319 448.4580259864282 523.333330294821 450.9632342879061C533.6111079419102 453.4684425893841 543.8888855889992 390.5877142222872 554.1666632360882 384.32469346859233C564.4444408831773 378.06167271489744 579.8611073538109 408.54170704954595 584.9999961773556 413.38510976573673 "
                transform="rotate(55, 400, 400)"
              ></path>
              <path
                d="M224.2500008380413 409.85992272654573C229.13194522040862 400.18146798850256 243.77777836751054 347.2672933141189 253.54166713224515 351.7891942982866C263.3055558969798 356.3110952824543 273.0694446617144 436.27734426563086 282.83333342644903 436.99132863155205C292.5972221911837 437.7053129974733 302.36111095591826 359.0877011499257 312.1249997206529 356.0731004938139C321.88888848538755 353.0584998377021 331.65277725012214 417.2377611743983 341.4166660148568 418.9037246948812C351.1805547795914 420.56968821536407 360.944443544326 367.4968503485534 370.70833230906067 366.068881616711C380.47222107379525 364.6409128848685 390.2361098385299 410.4945754962535 399.9999986032645 410.33591230382655C409.76388736799913 410.17724911139965 419.5277761327337 359.24636434235265 429.29166489746837 365.11690246214937C439.055553662203 370.98744058194603 448.8194424269376 444.60716186804507 458.5833311916722 445.5591410226067C468.3472199564069 446.5111201771683 478.1111087211415 370.35278781223826 487.8749974858761 370.8287773895191C497.63888625061077 371.3047669667999 507.4027750153453 446.0351305998875 517.16666378008 448.4150784862916C526.9305525448146 450.79502637269565 536.6944413095492 391.0583344239536 546.4583300742838 385.1084647079435C556.2222188390185 379.15859499193334 570.8680519861205 408.11462760984944 575.7499963684877 412.7158601902306 "
                transform="rotate(52.25, 400, 400)"
              ></path>
              <path
                d="M233.50000079393385 409.34097420503656C238.12500073512393 400.1719118216272 252.00000055869418 350.0426937090532 261.25000044107435 354.32659990458046C270.5000003234545 358.61050610010784 279.7500002058347 434.3680051368014 289.00000008821485 435.04441137820044C298.249999970595 435.72081761959953 307.4999998529752 361.2409748166597 316.7499997353554 358.3850373529748C325.99999961773557 355.5290998892899 335.24999950011573 416.3305053661601 344.49999938249584 417.9087865960912C353.74999926487607 419.4870678260223 362.99999914725623 369.2075372153596 372.2499990296364 367.8547247325615C381.49999891201657 366.5019122497634 390.7499987943968 409.9422241973913 399.9999986767769 409.7919116993026C409.24999855915706 409.6415992012139 418.4999984415373 361.39128731474835 427.7499983239174 366.95284974402944C436.9999982062976 372.5144121733105 446.24999808867767 442.25941128645695 455.4999979710579 443.16128627498904C464.7499978534381 444.0631612635211 473.99999773581817 371.9131621809558 483.2499976181984 372.36409967522184C492.4999975005786 372.8150371694878 501.7499973829587 443.6122237692551 510.9999972653389 445.86691124058524C520.2499971477191 448.1215987119154 529.4999970300993 391.52894318152823 538.7499969124794 385.89222450320284C547.9999967948596 380.2555058248774 561.8749966184298 407.6875367260611 566.49999655962 412.04659917063276 "
                transform="rotate(49.5, 400, 400)"
              ></path>
              <path
                d="M242.75000074982643 408.8220371276191C247.1180562498393 400.1623670988437 260.22222274987786 352.8181055480793 268.95833374990355 356.86401695496625C277.6944447499293 360.90992836185313 286.430555749955 432.45867745206374 295.1666667499807 433.0975055689406C303.9027777500064 433.7363336858175 312.63888875003215 363.39425992748545 321.3749997500579 360.6969856562275C330.1111107500836 357.99971138496954 338.84722175010927 415.4232610020136 347.583332750135 416.91385994139296C356.3194437501607 418.4044588807724 365.05555475018645 370.9182355262576 373.79166575021213 369.64057929250384C382.5277767502379 368.3629230587501 391.2638877502636 409.3898843426208 399.9999987502893 409.2479225388704C408.736109750315 409.10596073512 417.47222075034074 363.53622173123586 426.2083317503665 368.7888084700013C434.94444275039217 374.04139520876674 443.68055375041786 439.91167214896063 452.4166647504436 440.7634429714632C461.15277575046935 441.61521379396567 469.888886750495 373.4735479937651 478.6249977505207 373.89943340501634C487.36110875054646 374.3253188162676 496.09721975057215 441.1893283827144 504.8333307505979 443.3187554389707C513.5694417506236 445.44818249522694 522.3055527506492 391.99956338319464 531.0416637506751 386.67599574255394C539.7777747507007 381.3524281019133 552.8819412507394 407.26045728636456 557.2499967507522 411.3773495951267 "
                transform="rotate(46.75, 400, 400)"
              ></path>
              <path
                d="M252.000000705719 408.3030847914127C256.1111117645546 400.15280711727104 268.44444494106153 355.59350212831635 276.66666705873274 359.40141874656285C284.88888917640406 363.20933536480936 293.1111112940753 430.549334508537 301.33333341174654 431.15058450089174C309.5555555294178 431.7518344932464 317.77777764708907 365.54752977952216 325.99999976476033 363.00891870069114C334.2222218824316 360.4703076218601 342.44444400010286 414.51600137907803 350.6666661177741 415.9189180279057C358.8888882354454 417.3218346767334 367.11111035311666 372.6289185783665 375.3333324707879 371.4264185936571C383.55555458845913 370.22391860894766 391.77777670613045 408.8375292290613 399.99999882380166 408.7039181196492C408.222220941473 408.570307010237 416.4444430591442 365.6811408889343 424.6666651768155 370.62475193718416C432.8888872944867 375.56836298543396 441.111109412158 437.5639177526753 449.33333152982925 438.36558440914825C457.5555536475005 439.1672510656212 465.7777757651718 375.03391854778533 473.99999788284305 375.43475187602183C482.2222200005143 375.83558520425834 490.4444421181855 438.7664177373847 498.66666423585684 440.7705843785671C506.8888863535281 442.7747510197495 515.1111084711993 392.470168326072 523.3333305888706 387.459751723116C531.5555527065419 382.44933512016013 543.8888858830487 406.833362587879 547.9999969418843 410.7080847608315 "
                transform="rotate(44, 400, 400)"
              ></path>
              <path
                d="M261.2500006616116 407.78414389929804C265.10416727927 400.14325857979026 276.66666713224515 358.36891015264524 284.37500036756194 361.9388319822513C292.0833336028788 365.50875381185745 299.79166683819557 428.6400030091021 307.5000000735124 429.2036748769346C315.2083333088292 429.76734674476717 322.916666544146 367.7008110756506 330.6249997794628 365.32086318924655C338.33333301477967 362.94091530284254 346.04166625009645 413.6087532002343 353.74999948541324 414.92398755851025C361.45833272073 416.23922191678616 369.16666595604687 374.33961307456724 376.87499919136366 373.21226933890216C384.58333242668044 372.0849256032371 392.2916656619973 408.28518555959363 399.9999988973141 408.1599251445197C407.70833213263086 408.0346647294458 415.41666536794776 367.82607149072453 423.12499860326454 372.46070684845876C430.83333183858133 377.095342206193 438.5416650738981 435.2161748004817 446.2499983092149 435.96773729092513C453.9583315445317 436.7192997813685 461.66666477984853 376.5943005458974 469.3749980151653 376.9700817911191C477.08333125048216 377.3458630363408 484.79166448579895 436.3435185361468 492.49999772111573 438.2224247622553C500.2083309564326 440.10133098836377 507.9166641917494 392.9407847130411 515.6249974270662 388.2435191477699C523.333330662383 383.54625358249876 534.8958305153583 406.40627933348514 538.7499971330167 410.0388313706282 "
                transform="rotate(41.25, 400, 400)"
              ></path>
              <path
                d="M270.5000006175041 407.26520300718335C274.0972227939853 400.1337100423094 284.8888893234288 361.1443181769741 292.0833336763912 364.4762452179398C299.2777780293535 367.8081722589055 306.47222238231586 426.73067150966716 313.6666667352782 427.2567652529775C320.8611110882406 427.78285899628787 328.05555544120296 369.85409237177913 335.2499997941653 367.632807677802C342.4444441471277 365.41152298382485 349.63888850009 412.7015050213906 356.83333285305235 413.92905708911474C364.0277772060147 415.15660915683895 371.2222215589771 376.050307570768 378.4166659119394 374.9981200841472C385.61111026490175 373.9459325975265 392.8055546178641 407.73284189012594 399.9999989708265 407.6159321693903C407.19444332378885 407.49902244865467 414.3888876767512 369.97100209251477 421.5833320297136 374.2966617597334C428.7777763826759 378.622321426952 435.9722207356382 432.86843184828814 443.1666650886006 433.569890172702C450.361109441563 434.2713484971158 457.5555537945253 378.15468254400946 464.74999814748764 378.50541170621636C471.94444250045 378.85614086842327 479.1388868534124 433.9206193349089 486.33333120637474 435.6742651459435C493.5277755593371 437.42791095697805 500.7222199122994 393.41140110001027 507.91666426526183 389.0272865724238C515.1111086182241 384.64317204483734 525.9027751476676 405.97919607909137 529.4999973241488 409.3695779804249 "
                transform="rotate(38.5, 400, 400)"
              ></path>
              <path
                d="M279.7500005733967 406.74625830037144C283.09027830870065 400.12415769013137 293.1111115146125 363.9197223866057 299.7916669852204 367.013654638931C306.4722224558283 370.10758689125623 313.15277792643616 424.82133619553497 319.83333339704404 425.30985181432317C326.513888867652 425.79836743311137 333.19444433825987 372.0073698532104 339.87499980886776 369.94474835166017C346.5555552794757 367.88212685011 353.2361107500836 411.79425302784955 359.91666622069147 412.934122805022C366.59722169129935 414.0739925821945 373.2777771619073 377.7609982522714 379.9583326325152 376.783967014695C386.63888810312307 375.8069357771186 393.31944357373095 407.180494405961 399.9999990443389 407.07193537956357C406.6805545149468 406.9633763531662 413.36110998555466 372.11592887960774 420.0416654561626 376.13261285631074C426.72222092677043 380.14929683301375 433.4027763973784 430.52068508139735 440.08333186798626 431.1720392397816C446.76388733859415 431.8233933981659 453.4444428092021 379.7150607274242 460.12499827981 380.0407378066164C466.80555375041786 380.3664148858085 473.4861092210258 431.49771631897374 480.1666646916337 433.1261017149344C486.84722016224157 434.75448711089507 493.52777563284945 393.88201367228214 500.20833110345734 389.8110501823804C506.8888865740653 385.7400866924787 516.9097197799771 405.5521090100003 520.249997515281 408.70032077552423 "
                transform="rotate(35.75, 400, 400)"
              ></path>
              <path
                d="M289.00000052928925 406.22732122295406C292.08333382341596 400.11461296734785 301.3333337057961 366.6951342256318 307.5000002940496 369.5510716893167C313.66666688230305 372.4070091530016 319.83333347055645 422.91200851079725 326.0000000588099 423.3629460050633C332.1666666470634 423.81388349932934 338.3333332353168 374.16065496403615 344.49999982357025 372.2566966549129C350.6666664118237 370.35273834578965 356.8333330000771 410.88700866370306 362.9999995883306 411.9391961503238C369.16666617658404 412.99138363694453 375.3333327648375 379.4716965631694 381.4999993530909 378.56982157463733C387.6666659413444 377.6679465861053 393.83333252959784 406.6281545511905 399.9999991178513 406.5279462191314C406.1666657061047 406.4277378870723 412.3333322943582 374.26086329609524 418.49999888261164 377.9685715822826C424.66666547086504 381.67627986847003 430.83333205911845 428.172945943901 436.99999864737197 428.7741959362557C443.1666652356254 429.3754459286104 449.33333182387884 381.27544654023353 455.4999984121323 381.5760715364109C461.6666650003857 381.87669653258826 467.8333315886391 429.07482093243306 473.99999817689263 430.57794591331987C480.1666647651461 432.0810708942066 486.3333313533995 394.35263387394855 492.49999794165296 390.5948214217316C498.66666452990637 386.8370089695146 507.91666441228654 405.12502957030375 510.9999977064133 408.0310712000182 "
                transform="rotate(33, 400, 400)"
              ></path>
              <path
                d="M298.2500004851818 405.7083746087935C301.0763893381313 400.1050587078211 309.5555558969798 369.47053652791476 315.2083336028788 372.0884792029592C320.86111130877777 374.7064218780037 326.51388901467675 421.0026712893165 332.1666667205758 421.4160306590603C337.8194444264748 421.8293900288042 343.47222213237376 376.3139305381187 349.12499983827274 374.5686354214224C354.7777775441717 372.8233403047261 360.4305552500707 409.9797547628134 366.0833329559697 410.9442599588824C371.7361106618687 411.9087651549514 377.3888883677677 381.18238533732426 383.0416660736667 380.3556665978365C388.6944437795657 379.5289478583488 394.3472214854647 406.0758051596769 399.99999919136366 405.98394752195605C405.65277689726264 405.8920898842352 411.3055546031617 376.4057881758396 416.95833230906067 379.80452077151136C422.61111001495965 383.20325336718315 428.26388772085863 425.82519726966154 433.9166654267576 426.37634309598667C439.5694431326566 426.92748892231185 445.2222208385556 382.8358228162997 450.87499854445457 383.1113957294623C456.52777625035355 383.3869686426249 462.18055395625254 426.65191600914926 467.8333316621516 428.02978057496216C473.4861093680506 429.407645140775 479.13888707394955 394.82324453887173 484.79166477984853 391.37858312433957C490.4444424857476 387.93392170980735 498.923609044596 404.6979405938641 501.74999789754554 407.36181208776895 "
                transform="rotate(30.25, 400, 400)"
              ></path>
              <path
                d="M307.50000044107435 405.1894260872843C310.06944485284663 400.0955025409458 317.7777780881635 372.24593692284907 322.916666911708 374.6258848092532C328.0555557352525 377.0058326956572 333.19444455879704 419.093332160487 338.3333333823416 419.4691134057087C343.47222220588617 419.84489465093037 348.6111110294307 378.4672042048527 353.7499998529752 376.88057228058335C358.88888867651974 375.29394035631395 364.0277775000643 409.07249895457517 369.1666663236088 409.9493218600924C374.30555514715337 410.82614476560974 379.4444439706979 382.8930722041304 384.58333279424244 382.141509713687C389.722221617787 381.38994722324367 394.8611104413315 405.5234538608147 399.99999926487607 405.4399469174321C405.1388880884206 405.3564399740495 410.27777691196513 378.55071114823534 415.4166657355097 381.6404680533915C420.5555545590542 384.73022495854764 425.6944433825987 423.4774466880734 430.83333220614327 423.978488348369C435.97222102968783 424.4795300086646 441.11110985323234 384.39619718501723 446.2499986767769 384.646718015165C451.3888875003214 384.8972388453128 456.52777632386596 424.22900917851683 461.6666651474105 425.4816133292558C466.8055539709551 426.73421747999475 471.9444427944996 395.29385329644634 477.0833316180441 392.1623429195989C482.22222044158866 389.03083254275145 489.93055367690545 404.2708497100757 492.4999980886778 406.6925510681711 "
                transform="rotate(27.5, 400, 400)"
              ></path>
              <path
                d="M316.7500003969669 404.6704871025183C319.062500367562 400.0859559108136 326.0000002793471 375.0213468545266 330.6250002205372 377.16329995229023C335.25000016172726 379.3052530500539 339.87500010291734 417.1840025684007 344.5000000441074 417.52220568910025C349.1249999852975 417.86040880979976 353.7499999264876 380.62048740832984 358.37499986767773 379.1925186764874C362.99999980886776 377.76454994464495 367.6249997500579 408.16525268308004 372.2499996912479 408.9543932980456C376.87499963243806 409.74353391301116 381.4999995736281 384.6037686076798 386.1249995148182 383.92736236628076C390.74999945600825 383.25095612488167 395.3749993971984 404.97111209869564 399.9999993383884 404.8959558496513C404.62499927957856 404.82079960060696 409.24999922076864 380.69564365737415 413.8749991619587 383.4764248720147C418.4999991031488 386.2572060866553 423.12499904433884 421.1297056432285 427.7499989855289 421.5806431374945C432.37499892671906 422.03158063176056 436.9999988679091 385.9565810904779 441.6249988090992 386.1820498376109C446.2499987502893 386.4075185847439 450.8749986914794 421.8061118846275 455.4999986326694 422.93345562029265C460.12499857385956 424.0607993559577 464.74999851504964 395.7644715907641 469.3749984562397 392.9461122516014C473.9999983974298 390.1277529124387 480.9374983092149 403.84376836303056 483.24999827981 406.0232995853164 "
                transform="rotate(24.75, 400, 400)"
              ></path>
              <path
                d="M326.0000003528595 404.15154430305495C328.0555558822773 400.0764054659842 334.22222247053077 377.7967529715068 338.33333352936637 379.70071128063006C342.44444458820203 381.6046695897533 346.55555564703764 415.2746691616171 350.66666670587324 415.5752941577945C354.7777777647089 415.87591915397184 358.88888882354456 382.7737667971097 362.99999988238017 381.5044612576942C367.11111094121577 380.2351557182787 371.22222200005143 407.25800259688765 375.3333330588871 407.95946092130146C379.4444441177227 408.6609192457153 383.5555551765583 386.3144611965319 387.66666623539396 385.7132112041772C391.77777729422957 385.11196121182246 395.8888883530652 404.4187665218793 399.99999941190083 404.3519609671732C404.1111104707365 404.28515541246713 408.2222215295721 382.8405723518158 412.33333258840776 385.3123778759407C416.44444364724336 387.7841834000656 420.55555470607896 418.78196078368626 424.6666657649146 419.18279411192276C428.7777768237503 419.58362744015926 432.8888878825859 387.5169611812413 436.9999989414215 387.7173778453596C441.11111000025716 387.9177945094778 445.22222105909276 419.383210776041 449.3333321179284 420.38529409663215C453.444443176764 421.3873774172234 457.55555423559963 396.2350860703846 461.6666652944353 393.72987776890665C465.77777635327095 391.2246694674287 471.94444294152436 403.4166832012881 473.99999847094216 405.3540442877644 "
                transform="rotate(22, 400, 400)"
              ></path>
              <path
                d="M335.2500003087521 403.6326015035917C337.04861139699267 400.0668550211547 342.44444466171444 380.57215908848707 346.04166683819557 382.2381226089699C349.63888901467675 383.9040861294527 353.23611119115793 413.36533575483355 356.8333333676391 413.62838262648876C360.4305555441203 413.89142949814396 364.0277777206015 384.9270461858896 367.62499989708266 383.816403838901C371.22222207356384 382.7057614919124 374.81944425004497 406.3507525106953 378.41666642652615 406.96452854455737C382.01388860300733 407.5783045784195 385.6111107794885 388.02515378538396 389.2083329559697 387.4990600420736C392.8055551324509 386.97296629876325 396.40277730893206 403.86642094506294 399.99999948541324 403.8079660846952C403.5972216618944 403.7495112243273 407.1944438383756 384.98550104625735 410.7916660148568 387.1483308798667C414.38888819133797 389.311160713476 417.9861103678191 416.4342159241441 421.58333254430033 416.784945086351C425.1805547207815 417.1356742485579 428.77777689726264 389.07734127200473 432.3749990737438 389.2527058531082C435.972221250225 389.42807043421163 439.5694434267062 416.96030966745445 443.16666560318737 417.83713257297177C446.76388777966855 418.713955478489 450.3611099561497 396.70570055000513 453.9583321326309 394.5136432862119C457.55555430911204 392.32158602241867 462.9513875738338 402.9895980395457 464.7499986620744 404.68478899021244 "
                transform="rotate(19.25, 400, 400)"
              ></path>
              <path
                d="M344.50000407934186 403.11365679677976C346.04167072640524 400.05730266897666 350.6666706675953 383.34756329811864 353.7500039617221 384.7755320299611C356.8333372558488 386.2035007618035 359.9166705499755 411.45600044070136 363.00000384410225 411.6814691878344C366.08333713822896 411.9069379349674 369.16667043235566 387.0803236673208 372.2500037264824 386.12834451275916C375.3333370206091 385.1763653581976 378.4166703147358 405.44350051715423 381.5000036088626 405.96959426046465C384.5833369029893 406.495688003775 387.666670197116 389.73584446688744 390.75000349124275 389.2849069726214C393.83333678536945 388.83396947835536 396.91667007949616 403.314073460898 400.0000033736229 403.26396929486845C403.0833366677496 403.21386512883885 406.1666699618763 387.1304278333504 409.2500032560031 388.98428197644404C412.3333365501298 390.83813611953775 415.4166698442565 414.08646915725325 418.50000313838325 414.3870941534306C421.58333643250995 414.6877191496079 424.66666972663666 390.63771945541953 427.7500030207634 390.78803195350815C430.8333363148901 390.9383444515969 433.9166696090168 414.5374066515193 437.0000029031436 415.28896914196264C440.08333619727034 416.04053163240604 443.166669491397 397.176313122277 446.25000278552375 395.2974068961685C449.33333607965045 393.41850067006004 453.95833602084053 402.5625109704546 455.5000026679039 404.01553178531185 "
                transform="rotate(16.5, 400, 400)"
              ></path>
              <path
                d="M353.75000403523444 402.5947159046651C355.03472624112055 400.0477541314958 358.888892858779 386.1229713224475 361.4583372705513 387.31294526564955C364.0277816823235 388.50291920885155 366.5972260940958 409.54666894126643 369.16667050586807 409.73455956387727C371.73611491764035 409.92245018648816 374.30555932941263 389.2336049634493 376.87500374118486 388.4402890013146C379.44444815295714 387.6469730391799 382.0138925647294 404.53625233831053 384.58333697650164 404.97466379106913C387.152781388274 405.4130752438278 389.7222258000462 391.4465389630882 392.2916702118185 391.07075771786646C394.86111462359077 390.6949764726448 397.430559035363 402.7617297914303 400.0000034471353 402.719976319739C402.56944785890755 402.6782228480477 405.13889227067983 389.2753584351406 407.7083366824521 390.8202368877187C410.27778109422434 392.36511534029677 412.8472255059966 411.73872620505966 415.4166699177689 411.9892470352074C417.9861143295412 412.23976786535525 420.55555874131346 392.19810145353154 423.1250031530857 392.32336186860545C425.69444756485797 392.44862228367936 428.26389197663025 412.1145074502814 430.83333638840253 412.74080952565083C433.4027808001748 413.3671116010203 435.97222521194703 397.6469295092461 438.5416696237193 396.0811743208224C441.1111140354916 394.5154191323987 444.96528065315 402.1354277160608 446.25000285903616 403.34627839510847 "
                transform="rotate(13.75, 400, 400)"
              ></path>
              <path
                d="M363.000003991127 402.07577119785316C364.0277817558359 400.0382017793178 367.1111150499627 388.89837553207906 369.1666705793805 389.8503546866407C371.2222261087983 390.8023338412023 373.2777816382161 407.63733362713424 375.3333371676339 407.7876461252229C377.3888926970517 407.9379586233116 379.44444822646955 391.38688244488054 381.50000375588735 390.75222967517277C383.55555928530515 390.11757690546506 385.611114814723 403.6290003447695 387.6666703441408 403.9797295069764C389.7222258735586 404.3304586691834 391.7777814029764 393.1572296445916 393.8333369323942 392.8566046484143C395.888892461812 392.55597965223694 397.9444479912299 402.20938230726534 400.0000035206477 402.1759795299123C402.05555905006554 402.14257675255925 404.11111457948334 391.4202852222336 406.16667010890114 392.656187984296C408.22222563831895 393.89209074635846 410.27778116773675 409.3909794381688 412.33333669715455 409.59139610228704C414.3888922265724 409.7918127664053 416.4444477559902 393.75847963694633 418.500003285408 393.8586879690055C420.5555588148259 393.95889630106456 422.6111143442437 409.6916044343462 424.6666698736615 410.19264609464176C426.7222254030793 410.6936877549374 428.7777809324971 398.11754208151797 430.8333364619149 396.864937930779C432.88889199133274 395.61233378004005 435.97222528545944 401.70834064696976 437.00000305016835 402.6770211902079 "
                transform="rotate(11, 400, 400)"
              ></path>
              <path
                d="M372.2500001323223 401.55682649104125C373.020833455854 400.0286494271397 375.33333342644903 391.6737797417107 376.8750000735124 392.38776410763194C378.4166667205758 393.10174847355313 379.9583333676391 405.72799831300205 381.5000000147025 405.8407326865686C383.0416666617658 405.95346706013504 384.5833333088292 393.5401599263118 386.1249999558926 393.064170349031C387.66666660295596 392.58818077175016 389.2083332500193 402.7217483512285 390.74999989708266 402.9847952228837C392.291666544146 403.2478420945389 393.83333319120936 394.8679203260951 395.37499983827274 394.6424515789621C396.9166664853361 394.41698283182905 398.45833313239945 401.6570348231004 399.9999997794628 401.6319827400856C401.54166642652615 401.6069306570708 403.08333307358953 393.56521200932656 404.6249997206529 394.4921390808734C406.1666663677163 395.4190661524202 407.7083330147796 407.043232671278 409.249999661843 407.19354516936664C410.7916663089063 407.3438576674553 412.3333329559697 395.31885782036113 413.8749996030331 395.39401406940544C415.41666625009645 395.4691703184498 416.9583328971598 407.268701418411 418.49999954422316 407.6444826636327C420.04166619128654 408.0202639088544 421.58333283834986 398.58815465378984 423.12499948541324 397.6487015407356C424.6666661324766 396.70924842768136 426.97916610307163 401.28125357787866 427.7499994266033 402.0077639853073 "
                transform="rotate(8.25, 400, 400)"
              ></path>
              <path
                d="M381.50000008821485 401.0378855989266C382.0138889705693 400.0191008896589 383.5555556176327 394.44918776603953 384.5833333823416 394.92517734332034C385.6111111470505 395.40116692060116 386.6388889117594 403.8186668135671 387.6666666764683 403.89382306261143C388.6944444411772 403.9689793116558 389.72222220588617 395.69344122244024 390.749999970595 395.3761148375864C391.77777773530397 395.05878845273253 392.8055555000129 401.8145001723848 393.8333332647218 401.9898647534882C394.8611110294307 402.1652293345917 395.8888887941396 396.57861482229583 396.9166665588485 396.42830232420715C397.9444443235574 396.27798982611847 398.97222208826634 401.10469115363264 399.9999998529752 401.08798976495615C401.02777761768414 401.07128837627965 402.05555538239304 395.7101426111168 403.08333314710194 396.328093992148C404.11111091181084 396.94604537317923 405.13888867651974 404.69548971908443 406.16666644122864 404.7956980511435C407.19444420593754 404.89590638320266 408.2222219706465 396.87923981847314 409.24999973535535 396.92934398450274C410.2777775000643 396.9794481505323 411.3055552647732 404.84580221717306 412.3333330294821 405.0963230473209C413.361110794191 405.3468438774687 414.3888885588999 399.058771040759 415.4166663236088 398.4324689653895C416.4444440883177 397.80616689002 417.9861107353811 400.8541703234849 418.4999996177355 401.3385105951039 "
                transform="rotate(5.5, 400, 400)"
              ></path>
              <path
                d="M390.7500000441074 400.51894089211464C391.00694448528463 400.0095485374808 391.7777778088163 397.22459197567116 392.2916666911708 397.46258676431154C392.8055555735252 397.700581552952 393.3194444558797 401.9093314994349 393.8333333382342 401.9469096239571C394.3472222205886 401.9844877484793 394.8611111029431 397.8467187038715 395.3749999852975 397.68805551144453C395.888888867652 397.52939231901763 396.4027777500064 400.90724817884376 396.9166666323609 400.99493046939546C397.43055551471537 401.0826127599472 397.9444443970698 398.28930550379926 398.4583332794242 398.21414925475494C398.9722221617787 398.13899300571063 399.48611104413317 400.5523436694677 399.9999999264876 400.5439929751294C400.51388880884207 400.5356422807912 401.02777769119655 397.85506939820976 401.54166657355097 398.1640450887254C402.0555554559054 398.473020779241 402.56944433825987 402.3477429521936 403.08333322061435 402.3978471182231C403.5972221029688 402.44795128425267 404.11111098532325 398.43961800188794 404.6249998676777 398.46467008490276C405.13888875003215 398.48972216791753 405.6527776323866 402.4228992012379 406.16666651474105 402.5481596163118C406.68055539709553 402.6734200313857 407.19444427944995 399.52938361303086 407.7083331618044 399.2162325753461C408.22222204415885 398.90308153766136 408.99305536769054 400.4270832543938 409.24999980886776 400.6692533902033 "
                transform="rotate(2.75, 400, 400)"
              ></path>
            </g>
          </svg>
          <button className="close" onClick={toggleNav}>
            [ close ]
          </button>
          <div className="nav-items" ref={navItemsRef}>
            <a
              href="#home"
              onMouseEnter={() => handleLinkHover("home")}
              onMouseLeave={() => handleLinkHover(null)}
              onClick={toggleNav}
              className={isLinkHovered("home") ? "hovered" : ""}
            >
              home
            </a>
            <a
              href="#about"
              onMouseEnter={() => handleLinkHover("about")}
              onMouseLeave={() => handleLinkHover(null)}
              onClick={toggleNav}
              className={isLinkHovered("about") ? "hovered" : ""}
            >
              about
            </a>
            <a
              href="#work"
              onMouseEnter={() => handleLinkHover("work")}
              onMouseLeave={() => handleLinkHover(null)}
              onClick={toggleNav}
              className={isLinkHovered("work") ? "hovered" : ""}
            >
              work
            </a>
            <a
              href="#contact"
              onMouseEnter={() => handleLinkHover("contact")}
              onMouseLeave={() => handleLinkHover(null)}
              onClick={toggleNav}
              className={isLinkHovered("contact") ? "hovered" : ""}
            >
              contact
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
